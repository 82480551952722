import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Stack} from "@mui/joy";
import Button from "@mui/material/Button";
import addressIcon from "../../../images/Address.png";
import {useEffect, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import {makeStyles} from "@material-ui/core/styles";
import {extractFullAddress, isValidatePhone} from "../../../Utils/Helper";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import {Checkbox, Divider, FormControlLabel, IconButton, InputAdornment, MenuItem, Select} from "@mui/material";
import Modal from "@mui/material/Modal";
import HomeIcon from "@mui/icons-material/Home";
import {updateShippingLTLCreateLabelMoveFrom} from "../../../actions/shippingLTLAction";
import LoadingButton from "@mui/lab/LoadingButton";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import * as React from "react";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";

const styles = {
    ShippingLTLCreateLabelShipFromRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelShipFromSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelShipFromHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipFromSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelShipFromText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
        textTransform: 'none'
    },
    ShippingPackageCreateLabelShipFromWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageCreateLabelShipFromModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipFromSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipFromAddressText: {
        fontSize: '14px'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingLTLCreateLabelShipFrom = ({shipFromHistory, isResidential}) => {

    const dispatch = useDispatch();

    const token = getAccessToken("access_token");

    const classes = useStyles();

    const history = useHistory();

    const {id: partnerId, email} = useSelector((state) => state.user);
    let validation = useSelector(state => state?.shippingLTL?.createLabelValidation);
    let shipFromRedux = useSelector(state => state?.shippingLTL?.createLabelShipFrom);

    const [searchString, setSearchString] = useState('');
    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [searchAddressList, setSearchAddressList] = useState([]);
    const [verifiedAddress, setVerifiedAddress] = useState(null);
    const [isVerifiedAddress, setIsVerifiedAddress] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [shipFrom, setShipFrom] = useState({
        postalCode: null,
        province: null,
        city: null,
        parentCity: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: '',
        phone: null,
        email: '',
        company: '',
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });

    const verifyAddress = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/verifyAddress/forWeb`;
        let data = {
            addressLines: [shipFrom?.address, shipFrom?.address2],
            city: shipFrom?.city,
            province: shipFrom?.province?.code,
            postalCode: shipFrom?.postalCode,
            country: shipFrom?.country
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            const verifiedAddress = result?.data
            console.log('result verify address', verifiedAddress);
            // setVerifiedAddress(verifiedAddress);
            if (verifiedAddress?.customerMessages?.length > 0) {
                setIsVerifiedAddress(true);
                setVerifiedAddress(null);
            } else if (verifiedAddress?.missingKeys?.length > 0) {
                setIsVerifiedAddress(false);
                setVerifiedAddress(verifiedAddress);
            } else if (
                !verifiedAddress?.correction?.addressMatch ||
                !verifiedAddress?.correction?.cityMatch ||
                !verifiedAddress?.correction?.provinceMatch ||
                !verifiedAddress?.correction?.countryMatch ||
                !verifiedAddress?.correction?.postalCodeMatch
            ) {
                setVerifiedAddress(verifiedAddress);
                setIsVerifiedAddress(true);
            } else {
                setIsVerifiedAddress(false);
                setVerifiedAddress(verifiedAddress);
            }
        } catch (e) {
            console.log('error', e?.response);
            setIsVerifiedAddress(true);
            setVerifiedAddress(null)
        } finally {
            setLoading(false);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const searchAddress = async (searchString) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: 'SENDER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const handleInputChangeName = async (event, value) => {
        console.log('value', value);
        setShipFrom(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                parentCity: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handleInputChangePhone = async (event, value) => {
        console.log('value', value);
        // const trimmedValue = value.replace(/\s/g, '');
        setShipFrom(prevState => ({
            ...prevState,
            phone: value,
            addressList: {
                ...prevState.addressList,
                phone: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.phone.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangePhone = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                parentCity: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                phone: ""
            }))
        }
    }

    const handleInputChangeEmail = async (event, value) => {
        console.log('value', value);
        setShipFrom(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.address_email.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                parentCity: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleInputChangeCompany = async (event, value) => {
        console.log('value', value);
        setShipFrom(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.company_name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                parentCity: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        // await googleMapsGoogleAddress(value);
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                parentCity: address?.city || address?.administrative_area_level_3,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleAddress2 = event => {
        setShipFrom(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleCity = event => {
        setShipFrom(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setShipFrom(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setShipFrom(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handleSaveAddress = event => {
        setShipFrom(prevState => ({
            ...prevState,
            saveAddress: event.target.checked
        }))
    }

    const getShipFromAddress = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleSelectAddress = (address) => {
        console.log('select address', address);
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: address?.postal_code,
            province: {
                code: address?.province,
                name: address?.province
            },
            city: address?.city,
            parentCity: address?.city,
            address: address?.address,
            address2: address?.address_2,
            country: address?.country,
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            },
            name: address?.name,
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name,
            saveAddress: false,
            addressList: address
        }))
        handleClose();
    }

    const handleNewAddress = () => {
        history.push('/business/address-book')
    }

    const handleValidateAddress = () => {
        verifyAddress();
    }

    const handleAcceptRecommendation = () => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: verifiedAddress?.postalCode,
            province: {
                code: verifiedAddress?.stateOrProvinceCode,
                name: verifiedAddress?.stateOrProvinceCode
            },
            city: verifiedAddress?.city,
            address: verifiedAddress?.streetLinesToken[0],
            address2: verifiedAddress?.streetLinesToken[1] || shipFrom?.address2,
            country: verifiedAddress?.countryCode,
            displayAddress: {
                description: `${verifiedAddress?.streetLinesToken[0]}, ${verifiedAddress?.city}, ${verifiedAddress?.stateOrProvinceCode}, ${verifiedAddress?.countryCode}`,
                placeId: null,
                text: verifiedAddress?.streetLinesToken[0]
            },
        }))
        setVerifiedAddress(null);
        setIsVerifiedAddress(false);
    }

    const handleSearchString = (event) => {
        setSearchString(event?.target?.value);
    }

    const handleSearchAddressBook = async () => {
        if (searchString) {
            const result = await searchAddress(searchString);
            setAddressList(result);
        } else {
            await getShipFromAddress();
        }
    }

    useEffect(() => {
        dispatch(updateShippingLTLCreateLabelMoveFrom(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        if (shipFromRedux) {
            setShipFrom(shipFromRedux);
        }
        getShipFromAddress();
    }, []);

    useEffect(() => {
        if (shipFromHistory) {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: shipFromHistory?.postalCode,
                province: {
                    code: shipFromHistory?.province?.code,
                    name: shipFromHistory?.province?.name
                },
                city: shipFromHistory?.city || shipFromHistory?.region,
                parentCity: shipFromHistory?.parentCity,
                country: shipFromHistory?.province?.countryCode,
                address: null,
                address2: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: '',
                phone: null,
                email: '',
                company: '',
                saveAddress: false,
                addressList: {
                    name: null,
                    phone: null,
                    email: null,
                    address_email: null,
                    company_name: null,
                    city: null,
                    province: null,
                    country: null
                }
            }))
        }
    }, [shipFromHistory])

    // console.log('address list', addressList);
    console.log('ship from', shipFrom);
    console.log('ship from redux', shipFromRedux);
    console.log('ship from history', shipFromHistory);
    console.log('validation', validation);
    console.log('verify address', verifiedAddress);

    return (
        <Box sx={styles.ShippingLTLCreateLabelShipFromRoot}>
            <Box sx={styles.ShippingPackageCreateLabelShipFromSection}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                }}>
                    <ShippingFromAndToIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelShipFromHeading}>
                        {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                    </Typography>
                </Box>
                <Stack direction='row' spacing={0} alignItems='center'>
                    <Button
                        onClick={handleOpen}
                        startIcon={<img src={addressIcon} width='30px'/>}>
                        <Typography style={styles.ShippingPackageCreateLabelShipFromText}>
                            Address Book
                        </Typography>
                    </Button>
                </Stack>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.NAME')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                value={shipFrom?.addressList}
                                fullWidth
                                options={searchAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.name}
                                onInputChange={handleInputChangeName}
                                onChange={handleChangeName}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('name') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ''
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.PHONE')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                value={shipFrom?.addressList}
                                fullWidth
                                options={searchAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.phone}
                                onInputChange={handleInputChangePhone}
                                onChange={handleChangePhone}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.phone} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('phone') ? intl.get('SHIPPING_INFORMATION.REQUIRED') :
                                        shipFrom?.phone === null ? "" :
                                            isValidatePhone(shipFrom?.phone) ? "" : intl.get('SHIPPING_INFORMATION.VALID_PHONE')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                <InputLabel required>
                                    {intl.get('SHIPPING_INFORMATION.EMAIL')}
                                </InputLabel>
                                <Typography style={{fontSize: '12px'}}>(receive bol label)</Typography>
                            </Box>
                            <Autocomplete
                                freeSolo
                                value={shipFrom?.addressList}
                                fullWidth
                                options={searchAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.address_email}
                                onInputChange={handleInputChangeEmail}
                                onChange={handleChangeEmail}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.address_email} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('email') ? intl.get('SHIPPING_INFORMATION.REQUIRED') :
                                        (validation && !validation?.shipFromEmailCheck) && intl.get('LANDING_PAGE.SIGN_UP.INVALID_EMAIL')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.COMPANY')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                value={shipFrom?.addressList}
                                fullWidth
                                options={searchAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.company_name}
                                onInputChange={handleInputChangeCompany}
                                onChange={handleChangeCompany}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.company_name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                value={shipFrom?.displayAddress}
                                fullWidth
                                options={shipFromAddressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.text}
                                onInputChange={handleInputChangeAddress}
                                onChange={handleChangeAddress}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.description}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('address') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                            </InputLabel>
                            <TextField
                                value={shipFrom?.address2}
                                fullWidth
                                variant='outlined'
                                onInput={handleAddress2}
                                size='small'
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.CITY')}
                            </InputLabel>
                            <TextField
                                value={shipFrom?.city}
                                fullWidth
                                variant='outlined'
                                onInput={handleCity}
                                size='small'
                            />
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('city') ?
                                        intl.get('SHIPPING_INFORMATION.REQUIRED') :
                                        (validation !== null && !validation?.validateMovingFromCityProvinceCountry) && "City and Province are mapped"
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.PROVINCE')}
                            </InputLabel>
                            <TextField
                                value={shipFrom?.province?.code}
                                fullWidth
                                variant='outlined'
                                onInput={handleProvince}
                                size='small'
                            />
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('province') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.COUNTRY')}
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={shipFrom?.country}
                                    size='small'
                                    onChange={handleCountry}
                                >
                                    <MenuItem value='CA'>Canada</MenuItem>
                                    <MenuItem value='US'>Unites States</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('country') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                            </InputLabel>
                            <TextField
                                value={shipFrom?.postalCode}
                                fullWidth
                                variant='outlined'
                                onInput={handlePostalCode}
                                size='small'
                            />
                            <Typography style={styles.ShippingPackageCreateLabelShipFromWarningText}>
                                {
                                    validation?.shipFromMissing?.includes('postalCode') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipFromSectionBox}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={shipFrom?.saveAddress}
                                    onChange={handleSaveAddress}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('SHIPPING_INFORMATION.SAVE')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleValidateAddress}
                                loading={loading}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('SHIPPING_INFORMATION.VALIDATE')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {
                        (!isVerifiedAddress && verifiedAddress) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    Address Verified Successfully
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px'
                                }}>
                                    Your shipping address aligns perfectly with the Canada Post AddressComplete. You're
                                    good
                                    to go!
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        (verifiedAddress && isVerifiedAddress) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    Verify Your Shipping Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    Based on Canada Post AddressComplete, we've detected some discrepancies in the
                                    address
                                    you provided. For accurate shipping rates and timely delivery, consider our
                                    recommended
                                    address.
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                }}>
                                    Original Address
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {shipFrom?.address2} {shipFrom?.address}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}, {shipFrom?.postalCode}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}>
                                    Recommended Address
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {verifiedAddress?.streetLinesToken[1]} {verifiedAddress?.streetLinesToken[0]}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {verifiedAddress?.city}, {verifiedAddress?.stateOrProvinceCode}, {verifiedAddress?.countryCode}, {verifiedAddress?.postalCode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleAcceptRecommendation}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                    }}>
                                        Accept Recommendation
                                    </Typography>
                                </Button>
                            </Grid>
                        </>
                    }
                    {
                        (isVerifiedAddress && !verifiedAddress) &&
                        <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    Verify Your Shipping Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px'
                                }}>
                                    While your address did not fully match with Canada Post AddressComplete, it’s
                                    possible
                                    your shipment can still proceed normally.
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        isResidential &&
                        <Grid item xs={12}>
                            <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                                Attention: This is a residential address according to Canada Post.
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={styles.ShippingPackageCreateLabelShipFromModalBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', minWidth: 400}}>
                                <Typography style={{fontSize: '20px'}}>
                                    {intl.get('SELECT_ADDRESS.ADDRESS_LIST')}
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    {intl.get('SELECT_ADDRESS.ADD_NEW')}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px'}}>
                                    Search Address
                                </FormLabel>
                                <TextField
                                    value={searchString}
                                    onInput={handleSearchString}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    InputLabelProps={{style: {fontSize: 14}}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleSearchAddressBook}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                            }}>
                                {
                                    addressList?.length > 0 ? addressList.map((address, index) =>
                                            (
                                                <Box
                                                    sx={styles.ShippingPackageCreateLabelShipFromSelectBox}
                                                    key={index}
                                                    onClick={() => handleSelectAddress(address)}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={2}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <HomeIcon color='success'/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.name}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.address_email}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                                    {address?.phone}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        {/*<Grid item xs={2}>*/}
                                                        {/*    <Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                                                        {/*        <Button>*/}
                                                        {/*            */}
                                                        {/*        </Button>*/}
                                                        {/*    </Box>*/}
                                                        {/*</Grid>*/}
                                                    </Grid>
                                                </Box>

                                            ))
                                        : <Grid item xs={12}>
                                            <Typography>
                                                {intl.get('SELECT_ADDRESS.NO_ADDRESS')}
                                            </Typography>
                                        </Grid>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}
