import {Button, Container, Step, StepLabel, Stepper} from "@mui/material";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import Divider from "@mui/material/Divider";
import {Fragment, useEffect, useState} from "react";
import {getAccessToken} from "../../../Utils/doToken";
import {useHistory, useParams} from "react-router-dom";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../../images/NewDesignIcon/Package.svg";
import {ReactComponent as PackageTypeIcon} from "../../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as RateDetailsIcon} from "../../../images/NewDesignIcon/RateDetails.svg";
import {calTransactionFee, mapCarrierLogo} from "../../../Utils/Helper";
import {useInterval} from "usehooks-ts";
import {ShippingOrderDetailsPayment} from "../NewDesigin/ShippingOrderDetailsPayment";
import {BusinessShippingOrderDetailsPayment} from "./BusinessShippingOrderDetailsPayment";
import LoadingButton from "@mui/lab/LoadingButton";
import {greyButtonContained} from "../../../Utils/styleConfig";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

// BusinessShippingOrderDetails logic
// 1 call getBackendOrderInfoWithBackendOrderId api to get backend order information
// 2 set backend order status and order details
// 3 call getOrderStatus to get backend order status and set it if the status is 0 in every 2 seconds
// 4 call getShippingOrderInfo api to get shipping order info

export const BusinessShippingOrderDetails = () => {

    const accessToken = getAccessToken("access_token");

    const history = useHistory();

    const {orderId} = useParams();

    const {id} = useSelector((state) => state.user);

    const [delay, setDelay] = useState(2000);
    const [backendOrderStatus, setBackendOrderStatus] = useState(0);
    const [orderDetails, setOrderDetails] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [shippingOrderInfo, setShippingOrderInfo] = useState(null);
    const [selectPayment, setSelectPayment] = useState('card')
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbHandlePayment = (data) => {
        setSelectPayment(data);
    }

    const getBackendOrderStatus = async () => {
        try {
            const requestURI = `${PARTNER_URI}/looseItemRoute/getBackendOrderStatusByOrderId/${orderId}`;
            const {data} = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            console.log('[getBackendOrderStatus] data', data);
            setBackendOrderStatus(data?.orderStatus);
        } catch (e) {
            setBackendOrderStatus(0);
            console.log(e);
        }
    }

    const getBackendOrderInfoWithBackendOrderId = async (orderId) => {
        setPageLoading(true);
        try {
            const requestURI = `${PARTNER_URI}/looseItemRoute/getBackendOrderWithPackagesByOrderId/${orderId}`;
            const {data} = await axios.get(requestURI, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log('[getBackendOrderInfoWithBackendOrderId] data', data);
            setOrderDetails(data);
            setBackendOrderStatus(data?.status_id);
        } catch (e) {
            console.log(e);
        } finally {
            setPageLoading(false);
        }
    }

    const getShippingOrderInfo = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/looseItemRoute/getShippingOrderInfoByBackendOrderId/${orderId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log("shipping order info: ", data);
            const result = data.result;
            setShippingOrderInfo(result);
        } catch (e) {
            console.log(e?.response);
        }
    }

    const handleRefund = async () => {
        setIsLoadingCancelOrder(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/cancelBackendOrder/${orderId}`;
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            })
            console.log('success');
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`Your order has been successfully canceled, and a refund has been processed.`);
            setIsLoadingCancelOrder(false);
            setTimeout(() => getBackendOrderStatus(), 2000);
        } catch (e) {
            setErrorMessage(`Order cancellation failed. Please try again or contact support for assistance`);
            handleErrorMessage();
            setIsLoadingCancelOrder(false);
        }
    }

    useEffect(() => {
        if (orderId) {
            (async () => {
                await getBackendOrderInfoWithBackendOrderId(orderId);
            })();
        }
    }, [orderId]);

    useEffect(() => {
        if (backendOrderStatus === 1) {
            setSelectPayment('');
            (async () => {
                await getShippingOrderInfo();
            })()
        }
    }, [backendOrderStatus]);

    useInterval(
        () => {
            if (orderId) {
                getBackendOrderStatus();
            }
        },
        // Delay in milliseconds or null to stop it
        (backendOrderStatus === 0 || backendOrderStatus === 100) ? delay : null,
    );

    console.log("[BusinessShippingOrderDetails] backend order Details: ", orderDetails);
    console.log("[BusinessShippingOrderDetails] shipping order info: ", shippingOrderInfo);
    console.log("[BusinessShippingOrderDetails] backend order Id: ", orderId);
    console.log("[BusinessShippingOrderDetails] backend order status: ", backendOrderStatus);

    return (
        <Container maxWidth='xl'>
            <Stepper alternativeLabel
                     activeStep={backendOrderStatus === 1 ? 5 : orderDetails?.send_from_country === orderDetails?.send_to_country ? 3 : 4}
                // activeStep={orderDetails?.send_from_country === orderDetails?.send_to_country ? 3 : 4}
                     connector={<QontoConnector/>}>
                {
                    (orderDetails?.send_from_country === orderDetails?.send_to_country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 5 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ShipmentOverviewIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                        Place Order
                    </Typography>
                </Box>
                <Divider/>
                {
                    pageLoading ?
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 40px',
                            gap: '40px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 40px',
                            gap: '40px',
                            display: 'flex',
                        }}>
                            <Grid container spacing={4}>
                                <Grid item md={6} xs={12}>
                                    <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <ShippingFromAndToIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Shipping From
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_company || orderDetails?.sender}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_address_line2 && `${orderDetails?.pickup_address_line2} - `}{orderDetails?.pickup_address}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_area}, {orderDetails?.send_from_country}, {orderDetails?.pickup_postal_code}
                                                </Typography>
                                                {
                                                    orderDetails?.pickup_email &&
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {orderDetails?.pickup_email}
                                                    </Typography>
                                                }
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_phone} {orderDetails?.pickup_phone_ext && `ext. ${orderDetails?.pickup_phone_ext}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <ShippingFromAndToIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Shipping To
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_company || orderDetails?.deliver_name}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_address_line2 && `${orderDetails?.deliver_address_line2} - `}{orderDetails?.deliver_address}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_area}, {orderDetails?.send_to_country}, {orderDetails?.deliver_postal_code}
                                                </Typography>
                                                {
                                                    orderDetails?.deliver_email &&
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {orderDetails?.deliver_email}
                                                    </Typography>
                                                }
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_phone} {orderDetails?.deliver_phone_ext && `ext. ${orderDetails?.deliver_phone_ext}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <PackageDetailIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Package Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            {
                                                orderDetails?.packages?.length > 0 && orderDetails?.packages?.map((each, index) => (
                                                    <Fragment key={index}>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {`#${index + 1}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {each?.length} x {each?.width} x {each?.height} {each?.dimension_unit}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {each?.weight} {each?.weight_unit}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                x {each?.number}
                                                            </Typography>
                                                        </Grid>
                                                    </Fragment>
                                                ))
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <PackageTypeIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Additional Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            {
                                                orderDetails?.packages?.[0]?.signature_option &&
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Include signature options
                                                    </Typography>
                                                </Grid>
                                            }
                                            {
                                                orderDetails?.packages?.[0]?.battery_material &&
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Include lithium batteries
                                                    </Typography>
                                                </Grid>
                                            }
                                            {
                                                orderDetails?.is_return_label === 1 &&
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Return Label
                                                    </Typography>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Reference Order Number: {orderDetails?.ref_order_no || 'N/A'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <RateDetailsIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Rate Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <img
                                                    src={mapCarrierLogo(orderDetails?.account_base_combine_name?.split('-')[0]?.toUpperCase())}
                                                    alt="logo"
                                                    width='60px'/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Typography>
                                                            {orderDetails?.account_base_combine_name?.split('-')[0]?.toUpperCase()}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            $ {(+orderDetails?.prices?.final_total)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Shipping Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                ${(+orderDetails?.prices?.shipping_fee)?.toFixed(2)}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                Service Fee
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    ${(+orderDetails?.prices?.service_fee)?.toFixed(2)}
                                                                </Typography>
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Subtotal
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                ${(+orderDetails?.prices?.total)?.toFixed(2)}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Taxes
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            ${(+orderDetails?.prices?.totalTax)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    {
                                                        (+orderDetails?.prices?.brokerage_fee) > 0 &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    Total Brokerage Fee:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    ${(+orderDetails?.prices?.brokerage_fee)?.toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                    {
                                                        (+orderDetails?.prices?.duty_tax) > 0 &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    Total Duty and Tax
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    ${(+orderDetails?.prices?.duty_tax)?.toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            Total
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            $ {(+orderDetails?.prices?.final_total)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    {/*{*/}
                                                    {/*    ((backendOrderStatus === 1 && +shippingOrderInfo?.transactionFee > 0) || (backendOrderStatus === 0 && selectPayment === 'card')) &&*/}
                                                    {/*    <>*/}
                                                    {/*        <Grid item xs={6}>*/}
                                                    {/*            <Typography*/}
                                                    {/*                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>*/}
                                                    {/*                Transaction Fee*/}
                                                    {/*            </Typography>*/}
                                                    {/*        </Grid>*/}
                                                    {/*        <Grid item xs={6}>*/}
                                                    {/*            <Typography*/}
                                                    {/*                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>*/}
                                                    {/*                ${+shippingOrderInfo?.transactionFee?.toFixed(2) || calTransactionFee(+orderDetails?.prices?.unpaidAmount)}*/}
                                                    {/*            </Typography>*/}
                                                    {/*        </Grid>*/}
                                                    {/*    </>*/}
                                                    {/*}*/}
                                                    {/*<Grid item xs={12}>*/}
                                                    {/*    <Divider/>*/}
                                                    {/*</Grid>*/}
                                                    {/*<Grid item xs={6}>*/}
                                                    {/*    <Typography*/}
                                                    {/*        style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>*/}
                                                    {/*        Total Amount Due*/}
                                                    {/*    </Typography>*/}
                                                    {/*</Grid>*/}
                                                    {/*<Grid item xs={6}>*/}
                                                    {/*    <Typography*/}
                                                    {/*        style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>*/}
                                                    {/*        $*/}
                                                    {/*        {*/}
                                                    {/*            (backendOrderStatus === 1 && +shippingOrderInfo?.transactionFee > 0) ?*/}
                                                    {/*                (+orderDetails?.prices?.unpaidAmount + +shippingOrderInfo?.transactionFee)?.toFixed(2) :*/}
                                                    {/*                (backendOrderStatus === 0 && selectPayment === 'card') ?*/}
                                                    {/*                    (+orderDetails?.prices?.unpaidAmount + +calTransactionFee(+orderDetails?.prices?.unpaidAmount))?.toFixed(2) :*/}
                                                    {/*                    (+orderDetails?.prices?.unpaidAmount)?.toFixed(2)*/}
                                                    {/*        }*/}
                                                    {/*    </Typography>*/}
                                                    {/*</Grid>*/}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                }
            </Box>
            <Box sx={{
                marginBottom: '40px'
            }}>
                {
                    backendOrderStatus === 0 ?
                        <BusinessShippingOrderDetailsPayment orderDetails={orderDetails}
                                                             orderId={orderId}
                                                             cbHandlePayment={cbHandlePayment}
                                                             backendOrderStatus={backendOrderStatus}
                                                             shippingOrderInfo={shippingOrderInfo}/>
                        :
                        backendOrderStatus === 100 ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        textAlign: 'center'
                                    }}>
                                        Your payment was successfully processed, and the order is currently being
                                        processed.
                                    </Typography>
                                </Grid>
                            </Grid>
                            :
                            backendOrderStatus === 1 ?
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography sx={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            textAlign: 'center'
                                        }}>
                                            Thank you! Your order has been placed.
                                        </Typography>
                                    </Grid>
                                    < Grid item xs={12}>
                                        <Typography sx={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            textAlign: 'center'
                                        }}>
                                            {
                                                orderDetails?.send_from_country !== orderDetails?.send_to_country ?
                                                    "The next step is to upload the commercial invoice and schedule a pickup by pressing the respective buttons below." :
                                                    "The next step is to schedule a pickup by pressing the button below."}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}>
                                            {
                                                orderDetails?.send_from_country !== orderDetails?.send_to_country &&
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    onClick={() => history.push(`/business/cross-border-dashboard/${shippingOrderInfo.order_id}`)}
                                                >
                                                    <Typography sx={{textTransform: 'none'}}>
                                                        Upload Commercial Invoice
                                                    </Typography>
                                                </Button>
                                            }
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    backgroundColor: '#1D8B45',
                                                    "&:hover": {
                                                        backgroundColor: '#1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={() => history.push(`/business/schedulepickup`)}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Schedule Pickup
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}>
                                            <Button
                                                onClick={() => history.push(`/business/package-order-dashboard/${shippingOrderInfo?.order_id}`)}
                                            >
                                                <Typography sx={{textTransform: 'none', color: '#1D8B45'}}>
                                                    See Details of Order
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                :
                                backendOrderStatus === 2 ?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                textAlign: 'center'
                                            }}>
                                                Your payment was successfully processed, but the order was not
                                                generated. You can cancel the order and request a refund by clicking the
                                                'Refund' button. Please note that the refund may take up to 5 business
                                                days to be completed.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <LoadingButton
                                                    variant='contained'
                                                    sx={greyButtonContained}
                                                    onClick={handleRefund}
                                                    loading={isLoadingCancelOrder}
                                                >
                                                    <Typography sx={{textTransform: 'none'}}>
                                                        Refund
                                                    </Typography>
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    backendOrderStatus === 3 ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    textAlign: 'center'
                                                }}>
                                                    The order has been canceled, and the refund is being processed.
                                                    Please note that it may take up to 5 business days for the refund to
                                                    be completed.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        onClick={() => history.push(`/business/home`)}
                                                    >
                                                        <Typography sx={{textTransform: 'none'}}>
                                                            Back To Home Page
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    textAlign: 'center'
                                                }}>
                                                    The order has been canceled.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        onClick={() => history.push(`/business/home`)}
                                                    >
                                                        <Typography sx={{textTransform: 'none'}}>
                                                            Back To Home Page
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                }
            </Box>
        </Container>
    )

}