import {Container, FormLabel, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as React from "react";
import {MyQRCodeDisplay} from "../../MyQRCode/MyQRCodeDisplay";
import {PrintComponent} from "../../MyQRCode/PrintComponent";
import axios from "axios";
import {CHANGE_PASSWORD_API, PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import CircularProgress from "@mui/material/CircularProgress";
import {BusinessAccountSettingUserInformation} from "./BusinessAccountSettingUserInformation";
import {BusinessAccountSettingChangePassword} from "./BusinessAccountSettingChangePassword";
import {BusinessAccountSettingCompanyInformation} from "./BusinessAccountSettingCompanyInformation";
import {BusinessAccountSettingCreditCard} from "./BusinessAccountSettingCreditCard";
import {BusinessAccountSettingOneClickOrder} from "./BusinessAccountSettingOneClickOrder";

export const BusinessAccountSetting = () => {

    const storedToken = getAccessToken("access_token");

    const email = useSelector((state) => state.user.email);

    const [portfolio, setPortfolio] = useState({});

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorCard, setErrorCard] = useState(false);

    const getUserInfoAndCompany = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/login_middleware/getUserInfoWithCompany`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
            })
            const {data} = result;
            console.log('result', data);
            setPortfolio(prevState => ({
                ...prevState,
                email: data?.email,
                firstName: data?.firstname,
                lastName: data?.lastname,
                phone: data?.phone,
                companyEmail: data?.companyEmail,
                companyName: data?.companyName,
                companyPhone: data?.companyPhone,
                companyType: data?.companyType,
                address: data?.address,
                city: data?.city,
                province: data?.province,
                postalCode: data?.postalCode,
                isAdmin: data?.isAdmin
            }))
            setError(false);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getValidCardList = async () => {
        const requestURL = `${PARTNER_URI}/stripe/validCreditCardList`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })

            console.log('result', result);
            setCardList(result?.data);
            setIsLoading(false);
            setErrorCard(false);
        } catch (e) {
            console.log('error', e.response);
            setErrorCard(true);
        }
    }

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    useEffect(() => {
        (
            async () => {
                await getUserInfoAndCompany();
                await getValidCardList();
            }
        )()
    }, []);

    if (loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Account Settings
                    </Typography>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '20px',
                        marginBottom: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #D1D1D1',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center'
                    }}>
                        <CircularProgress/>
                    </Box>
                </Box>
            </Container>
        )
    }

    if (!loading && error) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Account Settings
                    </Typography>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '20px',
                        marginBottom: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                        border: '1px solid #D1D1D1',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: 600}}>
                            Sorry We currently encounter some issues, please try again later.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        )
    }

    console.log('portfolio', portfolio);

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Account Settings
                </Typography>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    border: '1px solid #D1D1D1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <BusinessAccountSettingUserInformation portfolio={portfolio}
                                                                   getUserInfoAndCompany={getUserInfoAndCompany}/>
                        </Grid>
                        <Grid item xs={12}>
                            <BusinessAccountSettingChangePassword email={email}
                                                                  getUserInfoAndCompany={getUserInfoAndCompany}/>
                        </Grid>
                        <Grid item xs={12}>
                            <BusinessAccountSettingCompanyInformation portfolio={portfolio}
                                                                      getUserInfoAndCompany={getUserInfoAndCompany}/>
                        </Grid>
                        <Grid item xs={12}>
                            <BusinessAccountSettingCreditCard cardList={cardList} cbGetValidCardList={cbGetValidCardList}/>
                        </Grid>
                        <Grid item xs={12}>
                            <BusinessAccountSettingOneClickOrder cardList={cardList}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    border: '1px solid #D1D1D1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <Grid item xs={12}>
                        <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                            QR Code
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 'auto',
                            justifyContent: 'center',
                            width: '50%'
                        }}>
                            <MyQRCodeDisplay/>
                            <PrintComponent/>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}