import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {checkIsPOBoxAddress, extractFullAddress, isValidatePhone} from "../../../Utils/Helper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/joy";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {Checkbox, Divider, FormControlLabel, IconButton, InputAdornment, MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCreateLabelValidation,
    updateShipToPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";
import {useHistory} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import {getCountryCode} from "../../../Utils/getCountryCode";
import addressIcon from "../../../images/Address.png";
import {getAccessToken} from "../../../Utils/doToken";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {useMediaQuery} from "@material-ui/core";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import * as React from "react";
import FormLabel from "@mui/material/FormLabel";
import SearchIcon from "@mui/icons-material/Search";
import {MuiTelInput} from "mui-tel-input";
import {green, greenButtonOutlined} from "../../../Utils/styleConfig";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";

const styles = {
    ShippingPackageCreateLabelShipToRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelShipToSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelShipToHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipToSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelShipToText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
        textTransform: 'none'
    },
    ShippingPackageCreateLabelShipToWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageCreateLabelShipToModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipToSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipToAddressText: {
        fontSize: '14px'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const BusinessShippingPackageCreateLabelShipTo = ({shipToHistory, swapShipTo}) => {

    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const token = getAccessToken("access_token");

    const dispatch = useDispatch();

    const history = useHistory();

    const {id: partnerId, email} = useSelector((state) => state.user);
    let validation = useSelector(state => state?.businessShippingPackage?.createLabelValidation);
    let shipToRedux = useSelector(state => state?.businessShippingPackage?.createLabelShipTo);

    const [isPOBoxAddress, setIsPOBoxAddress] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [shipToAddressPredictions, setShipToAddressPredictions] = useState([]);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [searchAddressList, setSearchAddressList] = useState([]);
    const [verifiedAddress, setVerifiedAddress] = useState(null);
    const [isVerifiedAddress, setIsVerifiedAddress] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [shipTo, setShipTo] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: '',
        phone: null,
        phoneExt: '',
        email: '',
        company: '',
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });

    const verifyAddress = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/verifyAddress/forWeb`;
        let data = {
            addressLines: [shipTo?.address, shipTo?.address2],
            city: shipTo?.city,
            province: shipTo?.province?.code,
            postalCode: shipTo?.postalCode,
            country: shipTo?.country
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            const verifiedAddress = result?.data
            console.log('result verify address', verifiedAddress);
            // setVerifiedAddress(verifiedAddress);
            if (verifiedAddress?.customerMessages?.length > 0) {
                setIsVerifiedAddress(true);
                setVerifiedAddress(null);
            } else if (verifiedAddress?.missingKeys?.length > 0) {
                setIsVerifiedAddress(false);
                setVerifiedAddress(verifiedAddress);
            } else if (
                !verifiedAddress?.correction?.addressMatch ||
                !verifiedAddress?.correction?.cityMatch ||
                !verifiedAddress?.correction?.provinceMatch ||
                !verifiedAddress?.correction?.countryMatch ||
                !verifiedAddress?.correction?.postalCodeMatch
            ) {
                setVerifiedAddress(verifiedAddress);
                setIsVerifiedAddress(true);
            } else {
                setIsVerifiedAddress(false);
                setVerifiedAddress(verifiedAddress);
            }
        } catch (e) {
            console.log('error', e?.response);
            setIsVerifiedAddress(true);
            setVerifiedAddress(null)
        } finally {
            setLoading(false);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country || 'ca'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const searchAddress = async (searchString) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: 'RECEIVER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const handleName = event => {
        setShipTo(prevState => ({
            ...prevState,
            name: event?.target?.value
        }))
    }

    const handleInputChangeName = async (event, value) => {
        console.log('value', value);
        setShipTo(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            console.log('result', result);
            const filterResult = result.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                value: value?.value,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.value}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handlePhone = value => {
        setShipTo(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    const handlePhoneExt = event => {
        const value = event?.target?.value;
        const regex = /^[0-9]{1,4}$/; // Only digits, up to 4 characters
        if (regex.test(value) || value === '') {
            setShipTo((prevState) => ({
                ...prevState,
                phoneExt: value
            }));
        }
    }

    const handleInputChangePhone = async (event, value) => {
        console.log('value', value);
        // const trimmedValue = value.replace(/\s/g, '');
        setShipTo(prevState => ({
            ...prevState,
            phone: value,
            addressList: {
                ...prevState.addressList,
                phone: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.phone.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangePhone = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                phone: ""
            }))
        }
    }

    const handleEmail = event => {
        setShipTo(prevState => ({
            ...prevState,
            email: event?.target?.value
        }))
    }

    const handleInputChangeEmail = async (event, value) => {
        console.log('value', value);
        setShipTo(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.address_email.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleCompany = event => {
        setShipTo(prevState => ({
            ...prevState,
            company: event?.target?.value
        }))
    }

    const handleInputChangeCompany = async (event, value) => {
        console.log('value', value);
        setShipTo(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.company_name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleAddress2 = event => {
        setShipTo(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address', shipTo?.country);
        setShipToAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setShipTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood || address?.region,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleCity = event => {
        setShipTo(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setShipTo(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setShipTo(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handleSaveAddress = event => {
        setShipTo(prevState => ({
            ...prevState,
            saveAddress: event.target.checked
        }))
    }

    const getShipToAddress = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleSelectAddress = (address) => {
        console.log('select address', address);
        setShipTo(prevState => ({
            ...prevState,
            postalCode: address?.postal_code,
            province: {
                code: address?.province,
                name: address?.province
            },
            city: address?.city,
            address: address?.address,
            address2: address?.address_2,
            country: address?.country,
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            },
            name: address?.name?.slice(0, 32),
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name?.slice(0, 32),
            saveAddress: false,
            addressList: {
                ...prevState?.addressList,
                company_name: address?.company_name?.slice(0, 32),
                name: address?.name?.slice(0, 32),
                email: address?.address_email,
                phone: address?.phone
            }
        }))
        handleClose();
    }

    const handleNewAddress = () => {
        if (+ownerRole === 1) {
            history.push('/settings/address-list')
        } else {
            history.push("/business/address-book");
        }
    }

    // console.log('ship to local', shipTo);

    const handleValidateAddress = () => {
        verifyAddress();
    }

    const handleAcceptRecommendation = () => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: verifiedAddress?.postalCode,
            province: {
                code: verifiedAddress?.stateOrProvinceCode,
                name: verifiedAddress?.stateOrProvinceCode
            },
            city: verifiedAddress?.city,
            address: verifiedAddress?.streetLinesToken[0],
            address2: verifiedAddress?.streetLinesToken[1] || shipTo?.address2,
            country: verifiedAddress?.countryCode,
            displayAddress: {
                description: `${verifiedAddress?.streetLinesToken[0]}, ${verifiedAddress?.city}, ${verifiedAddress?.stateOrProvinceCode}, ${verifiedAddress?.countryCode}`,
                placeId: null,
                text: verifiedAddress?.streetLinesToken[0]
            },
        }))
        setVerifiedAddress(null);
        setIsVerifiedAddress(false);
    }

    const handleSearchString = (event) => {
        setSearchString(event?.target?.value);
    }

    const handleSearchAddressBook = async () => {
        if (searchString) {
            const result = await searchAddress(searchString);
            setAddressList(result);
        } else {
            await getShipToAddress();
        }
    }

    const handleSelectRecommendation = async () => {
        setShipTo(prevState => ({
            ...prevState,
            province: validation?.validateShippingToCityProvinceAndCountry?.location?.state,
            city: validation?.validateShippingToCityProvinceAndCountry?.location?.city,
            country: validation?.validateShippingToCityProvinceAndCountry?.location?.country?.code
        }));
        const newValidation = {
            ...validation,
            validateShippingToCityProvinceAndCountry: {
                ...validation?.validateShippingToCityProvinceAndCountry,
                validation: true
            }
        }
        dispatch(updateCreateLabelValidation(newValidation));
    }

    useEffect(() => {
        dispatch(updateShipToPackageCreateLabelPackage(shipTo));
    }, [shipTo]);

    useEffect(() => {
        if (shipToRedux) {
            setShipTo(shipToRedux);
        }
        getShipToAddress();
    }, []);

    useEffect(() => {
        if (swapShipTo) {
            setShipTo(swapShipTo);
        }
    }, [swapShipTo]);

    useEffect(() => {
        const containPOBox = checkIsPOBoxAddress(shipTo?.address);
        setIsPOBoxAddress(containPOBox);
        // console.log('[ShippingPackageCreateLabelShipFrom] containPOBox', containPOBox);
    }, [shipTo?.address]);

    // console.log('address list', addressList);
    console.log('ship to', shipTo);
    console.log('ship to history', shipToHistory);

    // useEffect(() => {
    //     if (shipToHistory) {
    //         setShipTo(prevState => ({
    //             ...prevState,
    //             postalCode: shipToHistory?.postalCode,
    //             province: {
    //                 code: shipToHistory?.province?.code,
    //                 name: shipToHistory?.province?.name
    //             },
    //             city: shipToHistory?.city,
    //             address: shipToHistory?.displayAddress?.text,
    //             country: shipToHistory?.country?.code,
    //             displayAddress: shipToHistory?.displayAddress,
    //             saveAddress: false,
    //             addressList: shipToHistory?.addressList
    //         }))
    //     }
    // }, [shipToHistory])

    return (
        <Box sx={styles.ShippingPackageCreateLabelShipToRoot}>
            <Box sx={styles.ShippingPackageCreateLabelShipToSection}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                }}>
                    <ShippingFromAndToIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelShipToHeading}>
                        {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                    </Typography>
                </Box>
                <Stack direction='row' spacing={0} alignItems='center'>
                    <Button
                        onClick={handleOpen}
                        startIcon={<img src={addressIcon} width='30px'/>}>
                        <Typography style={styles.ShippingPackageCreateLabelShipToText}>
                            Address Book
                        </Typography>
                    </Button>
                </Stack>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.NAME')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={shipTo?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.name}
                                    onInputChange={handleInputChangeName}
                                    onChange={handleChangeName}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            maxLength: 32, // Set maxLength in inputProps
                                        }}
                                        error={validation?.shipToMissing?.includes('name')}
                                        helperText={validation?.shipToMissing?.includes('name') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                                <FormHelperText sx={{textAlign: 'right'}}>
                                    32 characters maximum
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.PHONE')}
                                </FormLabel>
                                <MuiTelInput
                                    value={shipTo?.phone}
                                    fullWidth
                                    variant="outlined"
                                    defaultCountry="CA"
                                    onChange={handlePhone}
                                    size='small'
                                    error={validation?.shipToMissing?.includes('phone') || (shipTo?.phone !== null && !isValidatePhone(shipTo?.phone))}
                                    helperText={validation?.shipToMissing?.includes('phone') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : (shipTo?.phone !== null && !isValidatePhone(shipTo?.phone) && intl.get('SHIPPING_INFORMATION.VALID_PHONE'))}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    Ext
                                </FormLabel>
                                <TextField
                                    value={shipTo?.phoneExt}
                                    fullWidth
                                    variant='outlined'
                                    onInput={handlePhoneExt}
                                    size='small'
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('SHIPPING_INFORMATION.EMAIL')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={shipTo?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.email}
                                    onInputChange={handleInputChangeEmail}
                                    onChange={handleChangeEmail}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.address_email} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('SHIPPING_INFORMATION.COMPANY')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={shipTo?.addressList}
                                    fullWidth
                                    options={searchAddressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.company_name}
                                    onInputChange={handleInputChangeCompany}
                                    onChange={handleChangeCompany}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            maxLength: 32, // Set maxLength in inputProps
                                        }}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option?.company_name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                        </Typography>
                                    )}
                                />
                                <FormHelperText sx={{textAlign: 'right'}}>
                                    32 characters maximum
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                                </FormLabel>
                                <Autocomplete
                                    value={shipTo?.displayAddress}
                                    freeSolo
                                    fullWidth
                                    options={shipToAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeAddress}
                                    onChange={handleChangeAddress}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant="outlined"
                                        className={classes.smallInput}
                                        error={validation?.shipToMissing?.includes('address')}
                                        helperText={validation?.shipToMissing?.includes('address') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                                {
                                    isPOBoxAddress &&
                                    <FormHelperText sx={{textAlign: 'left'}}>
                                        The address includes 'PO Box' and may be a PO Box. The PO Box address format is
                                        as follows:
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                PO Box [Box Number]
                                            </Grid>
                                            <Grid item xs={12}>
                                                [City] [Province/State] [Country] [Postal Code]
                                            </Grid>
                                        </Grid>
                                    </FormHelperText>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                                </FormLabel>
                                <TextField
                                    value={shipTo?.address2}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    onInput={handleAddress2}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.CITY')}
                                </FormLabel>
                                <TextField
                                    value={shipTo?.city}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    onInput={handleCity}
                                    error={validation?.shipToMissing?.includes('city')}
                                    helperText={validation?.shipToMissing?.includes('city') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.PROVINCE')}
                                </FormLabel>
                                <TextField
                                    value={shipTo?.province?.code}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    onInput={handleProvince}
                                    error={validation?.shipToMissing?.includes('province')}
                                    helperText={validation?.shipToMissing?.includes('province') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.COUNTRY')}
                                </FormLabel>
                                <FormControl fullWidth>
                                    <Select
                                        value={shipTo?.country}
                                        size='small'
                                        onChange={handleCountry}
                                        error={validation?.shipToMissing?.includes('country')}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    // width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {
                                            getCountryCode().map(v => {
                                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                    }}
                                >
                                    {validation?.shipToMissing?.includes('country') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                </FormLabel>
                                <TextField
                                    value={shipTo?.postalCode}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    onInput={handlePostalCode}
                                    error={validation?.shipToMissing?.includes('postalCode')}
                                    helperText={validation?.shipToMissing?.includes('postalCode') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    {
                        (
                            validation !== null &&
                            !validation?.validateShippingToCityProvinceAndCountry?.validation
                        ) &&
                        <>
                            <Grid item xs={12}>
                                <Box sx={{
                                    padding: '15px',
                                    border: '2px solid #FF8911',
                                    borderRadius: '5px',
                                    backgroundColor: '#FFD6A5',
                                }}>
                                    <Typography>
                                        Unfortunately, there is an issue with your <span
                                        style={{fontWeight: '600'}}>city, province/state, or country </span> input;
                                        they are either invalid or do not match.
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                validation?.validateShippingToCityProvinceAndCountry?.location?.length !== 0 &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Recommended City, Province/State, and Country
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            padding: '15px',
                                            border: '2px solid #1D8B45',
                                            borderRadius: '5px',
                                            "&:hover": {
                                                border: '2px solid #1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3.5}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>City: </span>{validation?.validateShippingToCityProvinceAndCountry?.location?.city}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3.5}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Province/State: </span>{validation?.validateShippingToCityProvinceAndCountry?.location?.state?.code}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3.5}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Country: </span>{validation?.validateShippingToCityProvinceAndCountry?.location?.country?.name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={1.5}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <Button
                                                            variant='outlined'
                                                            sx={greenButtonOutlined}
                                                            onClick={handleSelectRecommendation}
                                                        >
                                                            <Typography sx={{
                                                                textTransform: 'none',
                                                                fontWeight: '600',
                                                                color: green,
                                                            }}>
                                                                Select
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </>
                            }
                        </>
                    }
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={shipTo?.saveAddress}
                                    onChange={handleSaveAddress}
                                />}
                                label={
                                    <Typography>
                                        {intl.get('SHIPPING_INFORMATION.SAVE')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleValidateAddress}
                                loading={loading}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '16px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('SHIPPING_INFORMATION.VALIDATE')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {
                        (!isVerifiedAddress && verifiedAddress) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    Address Verified Successfully
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px'
                                }}>
                                    Your shipping address aligns perfectly with the Canada Post AddressComplete. You're
                                    good
                                    to go!
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        (verifiedAddress && isVerifiedAddress) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    Verify Your Shipping Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    Based on Canada Post AddressComplete, we've detected some discrepancies in the
                                    address
                                    you provided. For accurate shipping rates and timely delivery, consider our
                                    recommended
                                    address.
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                }}>
                                    Original Address
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {shipTo?.address2 && `${shipTo?.address2} - `}{shipTo?.address}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}, {shipTo?.postalCode}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}>
                                    Recommended Address
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {verifiedAddress?.streetLinesToken[1] && `${verifiedAddress?.streetLinesToken[1]} - `}{verifiedAddress?.streetLinesToken[0]}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {verifiedAddress?.city}, {verifiedAddress?.stateOrProvinceCode}, {verifiedAddress?.countryCode}, {verifiedAddress?.postalCode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleAcceptRecommendation}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                    }}>
                                        Accept Recommendation
                                    </Typography>
                                </Button>
                            </Grid>
                        </>
                    }
                    {
                        (isVerifiedAddress && !verifiedAddress) && <>
                            <Grid item xs={12}>
                                <Typography style={{
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    Verify Your Shipping Address
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px'
                                }}>
                                    While your address did not fully match with Canada Post AddressComplete, it’s possible
                                    your shipment can still proceed normally.
                                </Typography>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={styles.ShippingPackageCreateLabelShipToModalBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                minWidth: isMobile ? 300 : 400,
                            }}>
                                <Typography style={{fontSize: '20px'}}>
                                    {intl.get('SELECT_ADDRESS.ADDRESS_LIST')}
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    {intl.get('SELECT_ADDRESS.ADD_NEW')}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px'}}>
                                    Search Address
                                </FormLabel>
                                <TextField
                                    value={searchString}
                                    onInput={handleSearchString}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    InputLabelProps={{style: {fontSize: 14}}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleSearchAddressBook}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                            }}>
                                {
                                    addressList?.length > 0 ? addressList.map((address, index) =>
                                            (
                                                <Box
                                                    sx={styles.ShippingPackageCreateLabelShipToSelectBox}
                                                    key={index}
                                                    onClick={() => handleSelectAddress(address)}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={2}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <HomeIcon color='success'/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                                    {address?.name}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                                    {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                                    {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                                    {address?.address_email}
                                                                </Typography>
                                                                <Typography
                                                                    style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                                    {address?.phone}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        {/*<Grid item xs={2}>*/}
                                                        {/*    <Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                                                        {/*        <Button>*/}
                                                        {/*            */}
                                                        {/*        </Button>*/}
                                                        {/*    </Box>*/}
                                                        {/*</Grid>*/}
                                                    </Grid>
                                                </Box>

                                            ))
                                        : <Grid item xs={12}>
                                            <Typography>
                                                {intl.get('SELECT_ADDRESS.NO_ADDRESS')}
                                            </Typography>
                                        </Grid>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}