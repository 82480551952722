import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Divider, InputAdornment, MenuItem, Select, Snackbar} from "@mui/material";
import {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {
    generateRandomString,
    getMissingKeysFromObject,
    incoterms,
    internationalShipmentTypeList
} from "../../Utils/Helper";
import {Fragment} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormLabel from "@mui/material/FormLabel";
import {invoiceTypeCommercial, invoiceTypePersonal} from "../../Utils/config";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {green} from "../../Utils/styleConfig";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const requiredProperties = ["description", "itemDescription", "itemMaterial", "quantity"];

export const CrossBorderDashboardInvoiceForm = ({
                                                    trackingList,
                                                    itemList,
                                                    invoiceList,
                                                    closeCreateInvoice,
                                                    addInvoiceList,
                                                    shippingListData,
                                                    loadingGetTrackList,
                                                    errorGetTrackList,
                                                    shipmentType
                                                }) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [itemValue, setItemValue] = useState(0);
    const [invoiceType, setInvoiceType] = useState(1);
    const [packingList, setPackingList] = useState([]);
    const [availableTrackingList, setAvailableTrackingList] = useState([]);
    const [date, setDate] = useState();
    const [invoiceNo, setInvoiceNo] = useState();
    const [purchaseOrder, setPurchaseOrder] = useState();
    const [terms, setTerms] = useState('DDU');
    const [reason, setReason] = useState('SALE');
    const [comments, setComments] = useState('');
    const [freightCharge, setFreightCharge] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [tax, setTax] = useState(0);
    const [shipperTaxId, setShipperTaxId] = useState('');
    const [shipToTaxId, setShipToTaxId] = useState('');
    const [soldToTaxId, setSoldToTaxId] = useState('');
    const [validation, setValidation] = useState([]);
    const [recommendation, setRecommendation] = useState([]);
    const [hasMissingValue, setHasMissingValue] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [sameAsShipTo, setSameAsShipTo] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleConvertCurrency = async (amount) => {
        let requestURL = `${PARTNER_URI}/currency/convertCurrency`;
        let data = {
            fromCurrencyCode: "CAD",
            toCurrencyCode: "USD",
            amount: amount
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });
            return result?.data?.value;
        } catch (e) {
            return amount;
        }
    }

    const handleCheckTracking = (e) => {
        console.log(e.target.value);
        const updateId = e.target.value;

        const updatedTrackingList = availableTrackingList?.map(item => {
            if (item?.trackingId === updateId) {
                return {...item, isChecked: !item.isChecked};
            } else {
                return item;
            }
        });
        setAvailableTrackingList(updatedTrackingList);
    }

    const handleInvoiceType = e => {
        const type = e.target.value;
        setInvoiceType(type);
        if (+type === +invoiceTypePersonal) {
            setReason('PERSONAL USE');
        } else {
            setReason('COMMERCIAL');
        }
    }

    const handleDate = e => {
        setDate(e.target.value);
    }

    const handleInvoiceNo = e => {
        setInvoiceNo(e.target.value);
    }

    const handleUsingOrderId = () => {
        setInvoiceNo(shippingListData[0]?.order_id);
    }

    const handlePurchaseOrder = e => {
        setPurchaseOrder(e.target.value);
    }

    const handleTerms = e => {
        setTerms(e.target.value);
    }

    const handleReason = (e) => {
        setReason(e.target.value);
    }

    const handleComments = e => {
        setComments(e.target.value);
    }

    const handleFreightCharge = e => {
        const {value} = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setFreightCharge(value)
        }
    }

    const handleInsurance = e => {
        const {value} = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setInsurance(value)
        }
    }

    const handleTax = e => {
        const {value} = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setTax(value)
        }
    }

    const handleShipperTaxId = e => {
        setShipperTaxId(e.target.value);
    }

    const handleShipToTaxId = e => {
        setShipToTaxId(e.target.value);
    }

    const handleSoldToTaxId = e => {
        setSoldToTaxId(e.target.value);
    }

    const handleSameAsShipTo = e => {
        const checkValue = e?.target?.checked;
        setSameAsShipTo(checkValue);
        if (checkValue) {
            setSoldToTaxId(shipToTaxId);
        }
    }

    const handleCheckPacking = (e) => {
        // console.log(e.target.value);
        const updateId = +e.target.value;

        const updatedPackingList = packingList?.map(item => {
            if (item?.packingId === updateId) {
                return {...item, isChecked: !item.isChecked};
            } else {
                return item;
            }
        });
        setPackingList(updatedPackingList);
    }

    const handleItemName = (event, packingId) => {
        const value = event.target.value;
        const updatedPackingList = packingList?.map(item => {
            if (item?.packingId === packingId) {
                return {...item, description: value};
            } else {
                return item;
            }
        });
        setPackingList(updatedPackingList);
    }

    const handleItemDescription = (event, packingId) => {
        const value = event.target.value;
        const updatedPackingList = packingList?.map(item => {
            if (item?.packingId === packingId) {
                return {...item, itemDescription: value};
            } else {
                return item;
            }
        });
        setPackingList(updatedPackingList);
    }

    const handleItemMaterial = (event, packingId) => {
        const value = event.target.value;
        const updatedPackingList = packingList?.map(item => {
            if (item?.packingId === packingId) {
                return {...item, itemMaterial: value};
            } else {
                return item;
            }
        });
        setPackingList(updatedPackingList);
    }

    const handleQuantity = (event, packingId) => {
        const quantity = event.target.value;
        console.log('qty', quantity);
        console.log('packing id', packingId);

        const updatedPackingList = packingList?.map(item => {
            if (item?.packingId === packingId) {
                return {...item, quantity: quantity < 1 ? '1' : event.target.value};
            } else {
                return item;
            }
        });
        setPackingList(updatedPackingList);
    }

    const handleTaxId = () => {
        let missingKeys;

        if (+invoiceType === +invoiceTypeCommercial) {
            missingKeys = getMissingKeysFromObject({
                shipperTaxId,
                shipToTaxId,
                soldToTaxId
            })
            // } else if (+itemValue > 800) {
            //     missingKeys = getMissingKeysFromObject({
            //         shipToTaxId,
            //         soldToTaxId
            //     })
        } else {
            missingKeys = []
        }
        setRecommendation(missingKeys);
        return missingKeys?.length !== 0;
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        let missingKeys = getMissingKeysFromObject({
            invoiceType,
            date,
            invoiceNo,
            terms,
            reason,
        })

        setValidation(missingKeys);

        const validateTrackingNumbers = availableTrackingList.some(element => element.isChecked === true);
        const validateItems = packingList.some(element => element.isChecked === true);
        const hasMissingValues = packingList.some(object => requiredProperties.some((property) => !object[property]));

        setHasMissingValue(hasMissingValues);

        !validateTrackingNumbers && setErrorMessage(prevState => prevState + ' You have to select at least one tracking number.');
        missingKeys.length !== 0 && setErrorMessage(prevState => prevState + ' Missing Required in invoice information section.');
        !validateItems && setErrorMessage(prevState => prevState + ' You have to select at least one item.');
        hasMissingValues && setErrorMessage(prevState => prevState + ' Your item list has missing one or more required fields.');

        if (missingKeys.length === 0 &&
            validateTrackingNumbers &&
            validateItems &&
            !hasMissingValues
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const createInvoice = () => {
        const items = packingList.filter(element => element.isChecked === true);
        const trackingNumbers = availableTrackingList.filter(element => element.isChecked === true).map(item => item.trackingNumber);
        const trackingIds = availableTrackingList.filter(element => element.isChecked === true).map(item => item.trackingId);

        let invoiceSubtotal = 0;
        items.forEach(element => {
            invoiceSubtotal += ((+element.unitPrice) * (+element.quantity));
        })
        console.log('invoice subtotal', invoiceSubtotal);

        let invoiceTotalAmount = (+insurance) + (+tax) + (+freightCharge) + (+invoiceSubtotal);
        console.log('invoiceTotalAmount', invoiceTotalAmount);

        let totalWeight = 0;
        items.forEach(element => {
            totalWeight += ((+element.weight) * (+element.quantity));
        })

        let totalQuantity = 0;
        items.forEach(element => {
            totalQuantity += (+element.quantity);
        })

        let invoiceData = {
            orderId: shippingListData[0]?.order_id,
            trackingNumbers: trackingNumbers,
            trackingIds: trackingIds,
            tranId: shippingListData[0]?.tran_id,
            shippingIdNumber: shippingListData[0]?.shipping_id_no,
            items: items,
            date: date,
            billingNumber: "",
            invoiceNumber: invoiceNo,
            purchaseOrderNumber: purchaseOrder,
            termsOfSale: terms,
            reasonForExport: reason,
            currency: 'CAD',
            discount: "",
            invoiceSubTotal: invoiceSubtotal,
            freightCharges: freightCharge,
            insurance: insurance,
            tax: tax,
            comments: comments,
            invoiceTotalAmount: invoiceTotalAmount,
            totalQuantity: totalQuantity,
            totalWeight: totalWeight,
            invoiceLineTotal: "",
            shipperCompanyName: "",
            shipperContactName: shippingListData[0]?.send_from_name,
            shipperTaxId: shipperTaxId,
            shipperCompanyAddress: shippingListData[0]?.send_from_addr,
            shipperCompanyCity: shippingListData[0]?.send_from_city,
            shipperCompanyProvince: shippingListData[0]?.send_from_province,
            shipperCompanyPostalCode: shippingListData[0]?.send_from_postal_code,
            shipperCompanyCountry: shippingListData[0]?.send_from_country,
            shipperCompanyPhone: shippingListData[0]?.send_from_tel,
            shipperCompanyEmail: shippingListData[0]?.send_from_email,
            shipToTaxId: shipToTaxId,
            shipToContactName: shippingListData[0]?.send_to_name,
            shipToCompanyName: "",
            shipToCompanyAddress: shippingListData[0]?.send_to_addr,
            shipToCompanyCity: shippingListData[0]?.send_to_city,
            shipToCompanyProvince: shippingListData[0]?.send_to_province,
            shipToCompanyPostalCode: shippingListData[0]?.send_to_postal_code,
            shipToCompanyCountry: shippingListData[0]?.send_to_country,
            shipToCompanyPhone: shippingListData[0]?.send_to_tel,
            shipToCompanyEmail: shippingListData[0]?.send_to_email,
            soldToTaxId: soldToTaxId,
            soldToContactName: shippingListData[0]?.send_to_name,
            soldToCompanyName: "",
            soldToCompanyAddress: shippingListData[0]?.send_to_addr,
            soldToCompanyCity: shippingListData[0]?.send_to_city,
            soldToCompanyProvince: shippingListData[0]?.send_to_province,
            soldToCompanyPostalCode: shippingListData[0]?.send_to_postal_code,
            soldToCompanyCountry: shippingListData[0]?.send_to_country,
            soldToCompanyPhone: shippingListData[0]?.send_to_tel,
            soldToCompanyEmail: shippingListData[0]?.send_to_email,
            invoiceId: generateRandomString(8),
        }
        addInvoiceList(invoiceData);
        closeCreateInvoice();
    }

    const handleCreateInvoice = () => {
        const validationResult = validate();
        const recommendationResult = handleTaxId();
        if (validationResult) {
            if (recommendationResult) {
                handleDialogOpen();
            } else {
                createInvoice();
            }
        }
    }

    const returnMessage = () => {
        if (+invoiceType === +invoiceTypeCommercial) {
            return (
                <>
                    <Typography>
                        {
                            intl.getHTML('CROSS_BORDER.RECOMMENDATION.MESSAGE_1', {reason: `${reason}`})
                        }
                    </Typography>
                    {
                        recommendation?.map((tax) => (
                                <Typography sx={{fontWeight: '600'}}>
                                    {
                                        intl.get('CROSS_BORDER.RECOMMENDATION.MESSAGE_2', {
                                            tax: `${tax === 'shipperTaxId' ? 'Shipper Tax Id' : tax === 'shipToTaxId' ? 'Ship To Tax Id' : 'Sold To Tax Id'}`
                                        })
                                    }
                                </Typography>
                            )
                        )
                    }
                    <Typography>
                        {
                            intl.get('CROSS_BORDER.RECOMMENDATION.MESSAGE_3')
                        }
                    </Typography>
                </>
            )
        } else {
            return (
                <>
                    <Typography>
                        The reason for export is <span style={{fontWeight: '600'}}>{reason}</span>; however, the value
                        of the shipment is <span style={{fontWeight: '600'}}>${itemValue} USD</span>, which exceeds $800
                        USD. It is recommended to provide the following:
                    </Typography>
                    {
                        recommendation?.map((tax) => (
                                <Typography sx={{fontWeight: '600'}}>
                                    {tax}
                                </Typography>
                            )
                        )
                    }
                    <Typography>
                        Click Ignore to bypass this message and continue creating the invoice, or click No to provide
                        the tax ID.
                    </Typography>
                </>
            )
        }
    }

    const returnMessageByPurpose = () => {
        if (+invoiceType === +invoiceTypeCommercial) {
            return (
                <FormHelperText>
                    When exporting items for commercial purpose, please ensure to include the following details:
                    invoice date, invoice number, purchase order number, and terms of sale. It is recommended to provide
                    the shipper tax id, ship to tax id, and sold to tax id.
                </FormHelperText>

            )
        } else {
            return (
                <FormHelperText>
                    When exporting items for personal purpose and the value exceeds $800 USD. please ensure to include
                    the following details: invoice date, invoice number, purchase order number, and terms of sale. It is
                    recommended to provide the ship to tax id, and sold to tax id.
                </FormHelperText>
            )
        }
    }

    // get available tracking list
    useEffect(() => {
        if (invoiceList?.length > 0) {
            const trackingIds = invoiceList?.map(obj => obj.trackingIds).flat();
            console.log('tracking ids', trackingIds);
            const filteredTrackingList = trackingList?.filter(trackItem => !trackingIds?.includes(trackItem?.trackingId));
            console.log('filter tracking', filteredTrackingList);
            setAvailableTrackingList(filteredTrackingList);
        } else {
            setAvailableTrackingList(trackingList)
        }
    }, [invoiceList]);

    useEffect(() => {
        (
            async () => {
                if (itemList) {

                    const updateItemList = itemList?.map((itemList) => (
                        {
                            ...itemList,
                            itemDescription: "",
                            itemMaterial: ""
                        }
                    ))

                    setPackingList(updateItemList);

                    let itemValue = 0;
                    itemList.forEach(element => {
                        itemValue += ((+element.unitPrice) * (+element.quantity));
                    })

                    const itemValueInUSD = await handleConvertCurrency(itemValue);
                    setItemValue(itemValueInUSD);
                } else {
                    setItemValue(0);
                }
            }
        )()
    }, [itemList]);

    useEffect(() => {
        setReason(shipmentType);
        if (shipmentType === 'PERSONAL USE' || shipmentType === 'RELOCATION' || shipmentType === 'DONATION') {
            setInvoiceType(invoiceTypePersonal);
        } else if (shipmentType === "COMMERCIAL" || shipmentType === "SALE" || shipmentType === "REPAIR" || shipmentType === "SAMPLE" || shipmentType === "REPAIR" || shipmentType === "WARRANTY CLAIM" || shipmentType === "EXHIBITION") {
            setInvoiceType(invoiceTypeCommercial);
        } else {
            setInvoiceType(null);
        }
    }, [shipmentType]);

    if (!loadingGetTrackList && errorGetTrackList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography style={{fontSize: '14px'}}>
                    Sorry We currently encounter some issues, please try again later.
                </Typography>
                <Button
                    onClick={closeCreateInvoice}
                    variant='contained'
                    sx={{
                        backgroundColor: '#F2BE22',
                        "&:hover": {
                            backgroundColor: '#F2BE22',
                            filter: 'brightness(0.9)'
                        }
                    }}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        Close
                    </Typography>
                </Button>
            </Box>
        )
    }

    console.log('[CrossBorderDashboardInvoiceForm] itemValue', itemValue);
    console.log('[CrossBorderDashboardInvoiceForm] packingList', packingList);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    {intl.get('CROSS_BORDER.RECOMMENDATION.TITLE')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {returnMessage()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">
                        {
                            intl.get('CROSS_BORDER.RECOMMENDATION.NO')
                        }
                    </Button>
                    <Button onClick={createInvoice} variant="outlined" type="button">
                        {
                            intl.get('CROSS_BORDER.RECOMMENDATION.IGNORE')
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12}>
                <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                    Choose Package
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {
                        availableTrackingList?.length > 0 ? availableTrackingList?.map((element, index) => {
                                return (
                                    <Grid item md={4} xs={12} key={index}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Checkbox
                                                checked={element?.isChecked}
                                                onChange={handleCheckTracking}
                                                value={element?.trackingId}
                                            />
                                            <Typography style={{fontSize: '14px'}}>
                                                {element?.trackingNumber}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )
                            }) :
                            <Grid item xs={12}>
                                <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                                    No Available Tracking Numbers
                                </Typography>
                            </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                    Invoice Information
                </Typography>
                {returnMessageByPurpose()}
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>Type</FormLabel>
                    <RadioGroup
                        row
                        value={invoiceType}
                        onChange={handleInvoiceType}
                    >
                        <FormControlLabel
                            value={invoiceTypeCommercial}
                            control={<Radio
                                sx={{
                                    color: green,
                                    '&.Mui-checked': {
                                        color: green,
                                    },
                                }}/>}
                            label={
                                <Typography sx={{fontSize: '14px'}}>Commercial</Typography>
                            }/>
                        <FormControlLabel
                            value={invoiceTypePersonal}
                            control={<Radio
                                sx={{
                                    color: green,
                                    '&.Mui-checked': {
                                        color: green,
                                    },
                                }}/>}
                            label={
                                <Typography sx={{fontSize: '14px'}}>Personal</Typography>
                            }/>
                    </RadioGroup>
                    {
                        (validation?.includes('invoiceType') && !invoiceType) &&
                        <FormHelperText sx={{color: "error.main"}}>
                            Missing
                        </FormHelperText>
                    }
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>Date</FormLabel>
                    <TextField
                        value={date}
                        type='date'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        size='small'
                        fullWidth
                        onInput={handleDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={validation?.includes('date') && !date}
                        helperText={
                            (validation?.includes('date') && !date) && "Missing or Invalid"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>Invoice Number</FormLabel>
                    <TextField
                        value={invoiceNo}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        required
                        fullWidth
                        size='small'
                        onInput={handleInvoiceNo}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        sx={{color: '#1D8B45'}}
                                        onClick={handleUsingOrderId}
                                    >
                                        <Typography sx={{fontSize: '12px', textTransform: 'none', fontWeight: '600'}}>
                                            Use Order Id
                                        </Typography>
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                        error={validation?.includes('invoiceNo') && !invoiceNo}
                        helperText={
                            (validation?.includes('invoiceNo') && !invoiceNo) && "Missing or Invalid"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>Terms of Sale (Incoterm)</FormLabel>
                    <Select
                        value={terms}
                        onChange={handleTerms}
                        style={{fontSize: 14}} // font size of input text
                        // error={!validation && !terms}
                        size='small'
                    >
                        {
                            incoterms.map((each) => (
                                <MenuItem key={each.code} value={each.code}>
                                    {each?.code} - {each?.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>Reason for Export</FormLabel>
                    <Select
                        value={reason}
                        size='small'
                        style={{fontSize: 14}} // font size of input text
                        onChange={handleReason}
                    >
                        {
                            internationalShipmentTypeList?.map(v => (
                                <MenuItem
                                    key={v}
                                    value={v}
                                    disabled={
                                        (+invoiceType === +invoiceTypeCommercial && (v === "PERSONAL USE" || v === "DONATION" || v === "RELOCATION")) ||
                                        (+invoiceType === +invoiceTypePersonal && (v === "COMMERCIAL" || v === "SALE" || v === "REPAIR" || v === "SAMPLE" || v === "REPAIR" || v === "WARRANTY CLAIM" || v === "EXHIBITION"))
                                    }
                                >
                                    {v}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Shipper Tax Id</FormLabel>
                    <TextField
                        value={shipperTaxId}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        size="small"
                        onInput={handleShipperTaxId}
                        placeholder={+invoiceType === +invoiceTypeCommercial && 'Recommended to have'}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Ship To Tax Id</FormLabel>
                    <TextField
                        value={shipToTaxId}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        size="small"
                        onInput={handleShipToTaxId}
                        placeholder={(+invoiceType === +invoiceTypeCommercial || +itemValue > 800) && 'Recommended to have'}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Sold To Tax Id</FormLabel>
                    <TextField
                        value={soldToTaxId}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        size='small'
                        onInput={handleSoldToTaxId}
                        placeholder={(+invoiceType === +invoiceTypeCommercial || +itemValue > 800) && 'Recommended to have'}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{
                                    color: green,
                                    '&.Mui-checked': {
                                        color: green,
                                    },
                                }}
                                checked={sameAsShipTo}
                                onChange={handleSameAsShipTo}
                            />
                        }
                        label={<Typography sx={{fontSize: '14px'}}>Same as Ship To Tax ID</Typography>}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Purchase Order Number</FormLabel>
                    <TextField
                        value={purchaseOrder}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        required
                        fullWidth
                        onInput={handlePurchaseOrder}
                        size='small'
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Additional Comments</FormLabel>
                    <TextField
                        value={comments}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        size='small'
                        onInput={handleComments}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Freight Charge</FormLabel>
                    <TextField
                        value={freightCharge}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        type="number"
                        size="small"
                        onInput={handleFreightCharge}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Insurance</FormLabel>
                    <TextField
                        value={insurance}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        size="small"
                        onInput={handleInsurance}
                        type="number"
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>Tax</FormLabel>
                    <TextField
                        value={tax}
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        size="small"
                        type="number"
                        onInput={handleTax}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                    Customs Items
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                packingList?.map((element, index) => {
                    return (
                        <Fragment key={index}>
                            <Grid item md={4} xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px'}}>
                                        Item Name
                                    </FormLabel>
                                    <TextField
                                        value={element?.description}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        disabled={!element?.isChecked}
                                        onChange={(event) => handleItemName(event, element?.packingId)}
                                        error={hasMissingValue && element?.description?.trim() === ''}
                                        helperText={(hasMissingValue && element?.description?.trim() === '') && "Missing"}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px'}}>
                                        Item Description
                                    </FormLabel>
                                    <TextField
                                        value={element?.itemDescription}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        size='small'
                                        disabled={!element?.isChecked}
                                        onChange={(event) => handleItemDescription(event, element?.packingId)}
                                        error={hasMissingValue && element?.itemDescription?.trim() === ''}
                                        helperText={(hasMissingValue && element?.itemDescription?.trim() === '') && "Missing"}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px'}}>
                                        Item Material
                                    </FormLabel>
                                    <TextField
                                        value={element?.itemMaterial}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        size='small'
                                        disabled={!element?.isChecked}
                                        onChange={(event) => handleItemMaterial(event, element?.packingId)}
                                        error={hasMissingValue && element?.itemMaterial?.trim() === ''}
                                        helperText={(hasMissingValue && element?.itemMaterial?.trim() === '') && "Missing"}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px'}}>
                                        Quantity
                                    </FormLabel>
                                    <TextField
                                        value={element?.quantity}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        size='small'
                                        disabled={!element?.isChecked}
                                        onChange={(event) => handleQuantity(event, element?.packingId)}
                                        // error={!validation && itemDetail?.weight <= 0}
                                        // helperText={
                                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                                        // }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px'}}>
                                        HS Code
                                    </FormLabel>
                                    <TextField
                                        value={element?.hsCode}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        disabled={true}
                                        // onChange={(event) => handleItemDescription(event, element?.packingId)}
                                        // error={!validation && itemDetail?.weight <= 0}
                                        // helperText={
                                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                                        // }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px'}}>
                                        Unit Weight
                                    </FormLabel>
                                    <TextField
                                        value={element?.weight}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        disabled={true}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {element?.weightUnit}
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                min: 1
                                            },
                                            style: {fontSize: 14} // font size of input text
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px'}}>
                                        Unit Price
                                    </FormLabel>
                                    <TextField
                                        value={element?.unitPrice?.toFixed(2)}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        disabled={true}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </Fragment>
                    )
                })
            }
            <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px'}}>
                    <Button
                        onClick={handleCreateInvoice}
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        // size='small'
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Create
                        </Typography>
                    </Button>
                    <Button
                        onClick={closeCreateInvoice}
                        variant='contained'
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Close
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}