import Grid from "@material-ui/core/Grid";
import {Checkbox} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CardImages} from "../../../images/cards";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import CardImage from "../../../images/creditCardIcon.png";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const CreditCardList = ({cardList, cbSelectCardId}) => {

    const storedToken = getAccessToken("access_token");

    const [checkedId, setCheckedId] = useState('');

    console.log('card list', cardList);

    const firstLetterUpper = (_string) => {
        return _string.replace(/^\S/, (s) => s.toUpperCase());
    };

    const handleChange = (id) => {
        console.log('id', id);
        setCheckedId(id);
    };

    const setDefaultCard = async () => {
        const requestURL = `${PARTNER_URI}/stripe/setDefaultCreditCardByCardId`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    cardId: checkedId
                }
            })

            console.log(result);
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        const defaultCard = cardList?.find((card) => card.is_default === 1);
        setCheckedId(defaultCard?.id);
    }, [])

    useEffect(() => {
        cbSelectCardId(checkedId);
    },[checkedId])

    return (
        <Grid container spacing={0}>
            {
                cardList?.map((card, index) => (
                    <Fragment key={index}>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%',
                            }}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    checked={checkedId === card?.id ? true : false}
                                    onClick={() => handleChange(card?.id)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}>
                                <img
                                    src={CardImage}
                                    alt={firstLetterUpper(card?.card_type)}
                                    width="50px"
                                    align="bottom"
                                    style={{padding: "0 5px"}}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                height: '100%',
                            }}>
                                <img
                                    src={CardImages[firstLetterUpper(card?.card_type)]}
                                    alt={firstLetterUpper(card?.card_type)}
                                    width="40px"
                                    align="bottom"
                                    style={{padding: "0 5px"}}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%',
                            }}>
                                <Typography sx={{fontSize: '14px'}}>
                                    end with {card?.card_last_four_digits}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                height: '100%',
                            }}>
                                <Typography sx={{fontSize: '14px'}}>
                                    {card?.card_expiry}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                height: '100%',
                            }}>
                                <Typography sx={{fontSize: '14px'}}>
                                    {card?.card_holder_name}
                                </Typography>
                            </Box>
                        </Grid>
                    </Fragment>
                ))
            }
        </Grid>

    )
}


