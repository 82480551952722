import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    duplicateShippingLTLCreateLabelPalletList,
    removeShippingLTLCreateLabelPalletList, updateShippingLTLCreateLabelPalletList
} from "../../../actions/shippingLTLAction";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG, generateRandomString} from "../../../Utils/Helper";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import intl from "react-intl-universal";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {InputAdornment} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import duplicate from "../../../images/Duplicate.png";
import remove from "../../../images/Delete.png";
import CloseIcon from "@mui/icons-material/Close";

const palletType = [
    {name: '48"x40"', length: '48', width: '40'},
    {name: '48"x48"', length: '48', width: '48'},
    {name: 'Custom', length: null, width: null}
]

const palletTypeMetric = [
    {name: '122cmx102cm', length: '122', width: '102'},
    {name: '122cmx122cm', length: '122', width: '122'},
    {name: 'Custom', length: null, width: null}
]

const styles = {
    ShippingLTLQuickQuotePalletEachSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },

    ShippingLTLQuickQuotePalletEachActionSection: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    ShippingLTLQuickQuotePalletEachActionSectionMobile: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'flex-end',
    },
    ShippingLTLQuickQuotePalletEachSectionRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelPackageEachWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingLTLCreateLabelPalletEach = ({palletInfo}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    let validation = useSelector(state => state?.shippingLTL?.createLabelValidation);
    const metric = useSelector(state => state?.shippingLTL?.createLabelUnit);

    const [pallet, setPallet] = useState(palletInfo);
    const [descriptionError, setDescriptionError] = useState(false);

    const handleDescription = event => {

        const value = event?.target?.value;
        const regex = /^[\w\s\-\(\)\._]{1,30}$/;

        if (regex.test(value) || value === '') {
            setPallet(prevState => ({
                ...prevState,
                description: event.target.value
            }))
            setDescriptionError(false);
        } else {
            setDescriptionError(true)
        }
    }

    const handlePackageType = (event, value) => {
        if (value) {
            if (value?.name === 'Custom') {
                setPallet(prevState => ({
                    ...prevState,
                    packagingType: value
                }))
            } else {
                setPallet(prevState => ({
                    ...prevState,
                    packagingType: value,
                    length: value?.length,
                    width: value?.width,
                    height: metric ? '152' : '60'
                }))
            }
        }
    }

    const handleLength = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                length: value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                width: value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            if (pallet?.packagingType?.name === 'Custom') {
                setPallet(prevState => ({
                    ...prevState,
                    height: value
                }))
            } else {
                const height = metric ? '229' : '90';
                setPallet(prevState => ({
                    ...prevState,
                    height: value > (+height) ? height : value
                }))
            }
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            const weight = metric ? '1360' : '3000';
            setPallet(prevState => ({
                ...prevState,
                weight: value > (+weight) ? weight : value
            }))
        }
    }

    const handleQuantity = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handleRemove = () => {
        dispatch(removeShippingLTLCreateLabelPalletList(pallet))
    }

    const handleDuplicate = () => {
        const palletId = generateRandomString(8);
        const duplicatedPallet = {...pallet};
        duplicatedPallet.palletId = palletId;
        duplicatedPallet.description = `${duplicatedPallet.description}-copy`;
        dispatch(duplicateShippingLTLCreateLabelPalletList(duplicatedPallet));
    }

    const updatePackagingType = (metric, pallet) => {
        if (metric) {
            if (pallet?.name === '48"x40"') {
                return {name: '122cmx102cm', length: '122', width: '102'}
            } else if (pallet?.name === '48"x48"') {
                return {name: '122cmx122cm', length: '122', width: '122'}
            } else {
                return pallet;
            }
        } else {
            if (pallet?.name === '122cmx102cm') {
                return {name: '48"x40"', length: '48', width: '40'}

            } else if (pallet?.name === '122cmx122cm') {
                return {name: '48"x48"', length: '48', width: '48'}
            } else {
                return pallet;
            }
        }
    }

    useEffect(() => {
        const newPackagingType = updatePackagingType(metric, palletInfo?.packagingType);
        console.log('new packaging type', newPackagingType);
        if (newPackagingType?.name === 'Custom') {
            setPallet(prevState => ({
                ...prevState,
                lengthUnit: metric ? 'cm' : 'in',
                weightUnit: metric ? 'kg' : 'lb',
                packagingType: newPackagingType,
                weight: metric ? convertLBToKG(pallet?.weight) : convertKGToLB(pallet?.weight),
                length: metric ? convertINToCM(pallet?.length) : convertCMToIN(pallet?.length),
                width: metric ? convertINToCM(pallet?.width) : convertCMToIN(pallet?.width),
                height: metric ? convertINToCM(pallet?.height) : convertCMToIN(pallet?.height),

            }))
        } else {
            setPallet(prevState => ({
                ...prevState,
                lengthUnit: metric ? 'cm' : 'in',
                weightUnit: metric ? 'kg' : 'lb',
                packagingType: newPackagingType,
                length: newPackagingType?.length,
                width: newPackagingType?.width,
                height: metric ? convertINToCM(pallet?.height) : convertCMToIN(pallet?.height),
                weight: metric ? convertLBToKG(pallet?.weight) : convertKGToLB(pallet?.weight)
            }))
        }
    }, [metric]);

    useEffect(() => {
        setPallet(palletInfo);
    }, [palletInfo?.palletId]);
    //
    useEffect(() => {
        dispatch(updateShippingLTLCreateLabelPalletList(pallet));
    }, [pallet]);

    console.log('[ShippingLTLCreateLabelPalletEach] metric', metric);

    return (
        <Grid container spacing={1}>
            <Grid item xs={11}>
                <Grid container spacing={1}>
                    <Grid item md={5} xs={6}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.PALLET_NAME')}
                            </InputLabel>
                            <TextField
                                value={pallet?.description}
                                onInput={handleDescription}
                                fullWidth
                                variant='outlined'
                                placeholder='description'
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                            />
                            <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                {
                                    descriptionError ? "Max 30 characters. Only the following characters are allowed: letters (A-Z, a-z), digits (0-9), spaces (' '), hyphens ('-'), parentheses ('(', ')'), dots ('.'), and underscores ('_')." :
                                        validation?.hasMissingValues && (!pallet?.description && intl.get('SHIPPING_INFORMATION.REQUIRED'))
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.PALLET_TYPE')}
                            </InputLabel>
                            <Autocomplete
                                value={pallet?.packagingType}
                                fullWidth
                                options={metric ? palletTypeMetric : palletType}
                                getOptionLabel={option => option.name}
                                onChange={handlePackageType}
                                renderInput={(params) => <TextField
                                    variant="outlined"
                                    {...params}
                                    className={classes.smallInput}
                                />}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.WEIGHT')}
                            </InputLabel>
                            <TextField
                                value={pallet?.weight}
                                onInput={handleWeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {pallet?.weightUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                {
                                    pallet?.weight > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.LENGTH')}
                            </InputLabel>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    disabled={pallet?.packagingType?.name !== 'Custom'}
                                    value={pallet?.length}
                                    onInput={handleLength}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    placeholder='length'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {pallet?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14}
                                    }}
                                />
                                <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                    {
                                        pallet?.length > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.WIDTH')}
                            </InputLabel>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    disabled={pallet?.packagingType?.name !== 'Custom'}
                                    value={pallet?.width}
                                    onInput={handleWidth}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    placeholder='width'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {pallet?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14}
                                    }}
                                />
                                <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                    {
                                        pallet?.width > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.HEIGHT')}
                            </InputLabel>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    value={pallet?.height}
                                    onInput={handleHeight}
                                    fullWidth
                                    variant='outlined'
                                    type='number'
                                    placeholder='height'
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {pallet?.lengthUnit}
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            min: 1
                                        },
                                        style: {fontSize: 14}
                                    }}
                                />
                                <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                    {
                                        pallet?.height > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={1} xs={2}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'flex-end',
                            justifyContent: 'center'
                        }}>
                            <CloseIcon sx={{
                                fontSize: '35px',
                                color: '#1D8B45'
                            }}/>
                        </Box>
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.QUANTITY')}
                            </InputLabel>
                            <TextField
                                value={pallet?.quantity}
                                onInput={handleQuantity}
                                fullWidth
                                variant='outlined'
                                type='number'
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageCreateLabelPackageEachWarningText}>
                                {
                                    pallet?.quantity > 0 ? "" : intl.get('ADD_PALLET.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1} xs={12}>
                <Box sx={{
                    display: 'flex',
                    // flexDirection: isMobile ? 'row' : 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start',
                    height: '100%',
                    width: '100%',
                    gap: '10px'
                }}>
                    <IconButton
                        color='success'
                        onClick={handleDuplicate}
                        sx={{padding: 0}}
                    >
                        <img src={duplicate} width='30px'/>
                    </IconButton>
                    <IconButton
                        color='error'
                        onClick={handleRemove}
                        sx={{padding: 0}}
                    >
                        <img src={remove} width='30px'/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}